import React, { Component } from "react";
import "./adminPage.css";
import { Link } from "react-router-dom";
import { db, getUserId, signUserIn } from "../firebase";
import logo from "../assets/moored_logo_w.png";
import DeleteIcon from "@material-ui/icons/Delete";
import {
  Button,
  Grid,
  Divider,
  InputLabel,
  MenuItem,
  FormControl,
  Select,
  TextField,
  Tooltip,
  Card,
  fade,
  Zoom,
} from "@material-ui/core";
import { withStyles } from "@material-ui/core/styles";
import preloader from "../assets/manchor_preloader.svg";
import CategoryModal from "./components/CategoryModal";
import DeleteDialog from "./components/DeleteDialog";

function compare(a, b) {
  if (a.index < b.index) {
    return -1;
  }
  if (a.index > b.index) {
    return 1;
  }
  return 0;
}

const styles = (theme) => ({
  root: {
    paddingTop: 8,
    paddingLeft: 20,
    paddingRight: 10,
    marginBottom: 20,
    backgroundColor: "#fff",
    // display: "inline-block",
    wordBreak: "break-word",
  },
  response: {
    // border: "2px solid #e2e2e1",
    borderRadius: 4,
    backgroundColor: "#EFF7FF",
    transition: theme.transitions.create(["border-color", "box-shadow"]),
    "&:hover": {
      backgroundColor: "#F1F8FF",
    },
    "&:focused": {
      backgroundColor: "#F1F8FF",
      boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      // borderColor: theme.palette.primary.main,
    },
    input: {
      color: "white",
    },
    focused: {},
    // paddingBottom: 0,
  },
  button: {
    "&:disabled": {
      backgroundColor: "#2E5077",
      color: "#718BAA",
    },
  },
  reset: {
    color: "#FF607C",
    alignSelf: "flex-end",
  },
  accordion: {
    backgroundColor: "transparent",
    border: "none",
    boxShadow: "none",
    // padding: 0,
  },
  switchBase: {
    color: "#dfeeff",
    "&$checked": {
      // color: "#faf",
    },
    "&$checked + $track": {
      // backgroundColor: "#faf",
    },
  },
  timerField: {
    borderRadius: 4,
    backgroundColor: "#fff",
  },
  checked: {},
  track: {},
  margin: {
    margin: theme.spacing(1),
  },
});

class App extends React.Component {
  state = {
    text: "",
    message: "",
    prompt: "",
    title: "",
    categoryModalOpen: false,
    isDeleteDialogOpen: false,
    messageToDelete: null,
    categories: [],
    messages: [],
    responseMessage: "",
    timerInput: "",
    isLoading: false,
    isCountdownEnabled: false,
    timeRemaining: "",
    drawerOpen: false,
    deletionDate: "",
  };

  componentDidMount() {
    this.setState({ isLoading: true });
    const publicUrl = this.props.match.params.publicUrl;
    const { history } = this.props;
    //register user to see this page
    signUserIn().then(() => {
      let pValues = db.ref(`/userdata/${publicUrl}`);
      pValues.once("value").then(function (snapshot) {
        const data = snapshot.val();
        if (!data) {
          //redirect to 404
          history.push("/404");
        }
      });

      //sign user in and go on
      let publicValues = db.ref(`/userdata/${publicUrl}`);
      publicValues.on("value", (snapshot) => {
        const data = snapshot.val();
        if (data && data.creationTime) {
          this.setState({ deletionDate: data.creationTime + 7776000000 });
        }
        if (data !== null && "content" in data) {
          const content = data.content;
          if ("prompt" in content) {
            this.setState({ prompt: data.content.prompt });
          }
          if ("title" in content) {
            if (data.content.title === "") {
              // this.nameInput && this.nameInput.focus();
            }
            this.setState({ title: data.content.title });
          } else {
            // this.nameInput && this.nameInput.focus();
          }
          if ("categories" in content) {
            const categories = data.content.categories;
            let result = Object.keys(categories).map((key) => ({
              hash: key,
              ...categories[key],
            }));

            this.setState({ categories: result.sort(compare) });
          } else {
            this.setState({ categories: [] });
          }
          if ("responses" in content) {
            const messages = content.responses;
            let result = Object.keys(messages).map((key) => ({
              id: key,
              ...messages[key],
            }));
            this.setState({ messages: result });
          } else {
            this.setState({ messages: [] });
          }
          if ("timer" in content && content.timer.isEnabled) {
            // let timeRemaining = Math.max(0, Math.round((content.timer.countdownEnd - Date.now())/1000))
            if (content.timer.countdownEnd) {
              this.interval = setInterval(() => {
                const timeRemaining = Math.round(
                  (content.timer.countdownEnd - Date.now()) / 1000
                );
                if (timeRemaining === 0) {
                  this.setState({ timeRemaining: -1 });
                  db.ref(
                    `/userdata/${publicUrl}/content/timer/countdownEnd`
                  ).remove();
                  clearInterval(this.interval);
                } else {
                  this.setState({ timeRemaining });
                }
              }, 1000);
            } else {
              this.setState({
                timeRemaining: 0,
              });
            }
            this.setState({ isCountdownEnabled: true });
          } else {
            this.setState({ isCountdownEnabled: false });
          }
        } else {
          this.nameInput && this.nameInput.focus();
        }
        this.setState({ isLoading: false });
      });
    });
  }

  // componentWillUnmount() {
  //   clearInterval(this.interval);
  // }

  timeDifference(current, previous) {
    var msPerMinute = 60 * 1000;
    var msPerHour = msPerMinute * 60;
    var msPerDay = msPerHour * 24;
    var msPerMonth = msPerDay * 30;
    var msPerYear = msPerDay * 365;

    var elapsed = current - previous;

    if (elapsed < msPerMinute) {
      return "Just now";
    } else if (elapsed < 2 * msPerMinute) {
      return "1 minute ago";
    } else if (elapsed < msPerHour) {
      return Math.round(elapsed / msPerMinute) + " minutes ago";
    } else if (elapsed < 2 * msPerHour) {
      return "1 hour ago";
    } else if (elapsed < msPerDay) {
      return Math.round(elapsed / msPerHour) + " hours ago";
    } else if (elapsed < 2 * msPerDay) {
      return "1 day ago";
    } else if (elapsed < msPerMonth) {
      return Math.round(elapsed / msPerDay) + " days ago";
    } else if (elapsed < 2 * msPerMonth) {
      return "1 month ago";
    } else if (elapsed < msPerYear) {
      return Math.round(elapsed / msPerMonth) + " months ago";
    } else if (elapsed < 2 * msPerYear) {
      return "1 year ago";
    } else {
      return Math.round(elapsed / msPerYear) + " years ago";
    }
  }

  addZero(number) {
    if (number < 10) {
      return "0" + number;
    } else return number;
  }

  countdownTimer() {
    if (this.state.isCountdownEnabled) {
      let hours = Math.floor(this.state.timeRemaining / (60 * 60));

      let divisor_for_minutes = this.state.timeRemaining % (60 * 60);
      let minutes = Math.floor(divisor_for_minutes / 60);
      minutes = this.addZero(minutes);

      let divisor_for_seconds = divisor_for_minutes % 60;
      let seconds = Math.ceil(divisor_for_seconds);
      seconds = this.addZero(seconds);

      let time = "";

      if (this.state.timeRemaining < 0) {
        // time = 0;
        return (
          <div className="Admin-timer">
            <p className="Admin-timer-numbers" style={{ fontSize: "24px" }}>
              time's up
            </p>
          </div>
        );
      } else {
        if (hours > 0) {
          time = hours + ":";
        }
        if (minutes > 0) {
          time = time + minutes + ":";
        }
        time = time + seconds;
      }

      return (
        <div className="Admin-timer">
          <p className="Admin-timer-numbers">{time}</p>
        </div>
      );
    }
  }

  // handleToggle = (event) => {
  //   this.setState({ isCountdownEnabled: event.target.checked });
  //   const publicUrl = this.props.match.params.publicUrl;
  //   db.ref(`/userdata/${publicUrl}/content/timer/isEnabled`).set(
  //     event.target.checked
  //   );
  // };

  handleSubmit = (event) => {
    event.preventDefault();
  };

  getInputs = (childData) => {
    this.setState({ text: childData });
  };
  handleChange(event) {
    this.setState({ [event.target.name]: event.target.value });
  }
  convertArrayToObject = (array, key) => {
    const initialValue = {};
    return array.reduce((obj, item, index) => {
      return {
        ...obj,
        [item["hash"]]: {
          name: item.name,
          index: index,
        },
      };
    }, initialValue);
  };

  // onCategorySelect = (categoryId, message) => {
  //   const publicUrl = this.props.match.params.publicUrl;
  //   db.ref(
  //     `/userdata/${publicUrl}/content/responses/${message.id}/category`
  //   ).set(categoryId);
  // };

  // updateIndex = (items) => {
  //   const publicUrl = this.props.match.params.publicUrl;
  //   const newCategories = this.convertArrayToObject(items, "hash");

  //   db.ref(`/userdata/${publicUrl}/content/categories`).set(newCategories);
  // };

  // deleteResponse = () => {
  //   const { messageToDelete } = this.state;
  //   const publicUrl = this.props.match.params.publicUrl;

  //   db.ref(`/userdata/${publicUrl}/content/responses/${messageToDelete.id}`)
  //     .remove()
  //     .then(() => {
  //       this.handleDialogClose();
  //     });
  // };

  // handleDialogClose = () => {
  //   this.setState({ isDeleteDialogOpen: false, messageToDelete: null });
  // };

  // handleDialogOpen = (message) => {
  //   this.setState({ isDeleteDialogOpen: true, messageToDelete: message });
  // };

  isMessageUncategorized = (message, categories) => {
    if (!message.category) {
      return true;
    } else {
      const isMessageFound = categories.find(
        (category) => category.hash === message.category
      );
      return !isMessageFound;
    }
  };

  render() {
    const publicUrl = this.props.match.params.publicUrl;
    const { classes } = this.props;
    const {
      prompt,
      title,
      categoryModalOpen,
      categories,
      messages,
      isDeleteDialogOpen,
      messageToDelete,
      responseMessage,
      isLoading,
    } = this.state;

    const isCategoriesUsed = categories.length !== 0;
    return (
      <div className="Admin">
        <Grid container spacing={0}>
          <Grid item className="Admin-left-bar" xs={12} sm={3}>
            <div>
              <div className="Admin-logo-container">
                <Link to="/">
                  <img
                    src={logo}
                    className="Admin-left-bar-logo"
                    alt="Moored logo"
                    width="150px"
                  />
                </Link>
              </div>
              <div className="Admin-instructions">
                {/* <h2>Overview</h2> */}
                <p>
                  Moored is a tool for teams to collect ideas and feedback
                  anonymously.
                </p>
                <p>
                  Learn more <Link to={`/`}>here</Link>
                </p>

                <p style={{ marginTop: "30px", fontSize: "14px" }}>
                  Responses can only be deleted by the page owner.
                </p>

                <p style={{ marginTop: "20px", fontSize: "14px" }}>
                  This board will be available until{" "}
                  {Intl.DateTimeFormat("en-US", {
                    month: "long",
                    day: "numeric",
                  }).format(this.state.deletionDate + 7776000000)}
                </p>
              </div>
              {/* <h3>Submit a response</h3>
              <form>
                <TextField
                  id="outlined-multiline-static"
                  className={classes.response}
                  style={{
                    marginTop: "10px",
                    marginBottom: "30px",
                    display: "flex",
                  }}
                  label="Response"
                  onChange={(event) => {
                    this.setState({ responseMessage: event.target.value });
                  }}
                  value={responseMessage}
                  multiline
                  rows={3}
                  placeholder="Enter a response"
                  variant="filled"
                  inputProps={{
                    className: classes.input,
                    maxlength: "500",
                  }}
                />
                <Button
                  onClick={() => {
                    db.ref(`/userdata/${publicUrl}/content/responses/`)
                      .push({
                        text: responseMessage,
                        timestamp: Date.now(),
                      })
                      .then(() => {
                        this.setState({ responseMessage: "" });
                      });
                  }}
                  disabled={responseMessage === ""}
                  classes={{ root: classes.button, disabled: classes.disabled }}
                  variant="contained"
                  color="primary"
                  size="medium"
                >
                  Submit
                </Button>
              </form> */}
            </div>
          </Grid>
          <Grid item className="Admin-body" xs={12} sm={9}>
            {isLoading ? (
              <object
                className="preloader"
                type="image/svg+xml"
                data={preloader}
              />
            ) : (
              <div>
                <header className="Admin-header">
                  <h1 className="Admin-title">{this.state.title}</h1>
                  <div className="Admin-prompt">{this.state.prompt}</div>
                </header>
                <div className="Admin-bottom-half">
                  <div className="Admin-responses">
                    {this.countdownTimer()}
                    <div className="Admin-responses-header">
                      <div>Responses</div>
                    </div>
                    {/* <h3>Submit a response</h3> */}
                    <form className="Admin-responses-input">
                      <TextField
                        id="outlined-multiline-static"
                        // className={classes.response}
                        style={{
                          // marginTop: "10px",
                          // marginBottom: "30px",
                          paddingRight: "20px",
                          display: "flex",
                        }}
                        label="Add a response"
                        onChange={(event) => {
                          this.setState({
                            responseMessage: event.target.value,
                          });
                        }}
                        value={responseMessage}
                        multiline
                        fullWidth
                        rows={2}
                        // placeholder="Enter a response"
                        variant="outlined"
                        inputProps={{
                          className: classes.input,
                          maxLength: "500",
                        }}
                      />
                      <div className="Admin-responses-input-submit">
                        <Button
                          onClick={() => {
                            db.ref(`/userdata/${publicUrl}/content/responses/`)
                              .push({
                                text: responseMessage,
                                timestamp: Date.now(),
                              })
                              .then(() => {
                                this.setState({ responseMessage: "" });
                              });
                          }}
                          disabled={responseMessage === ""}
                          // classes={{ root: classes.button, disabled: classes.disabled }}
                          variant="contained"
                          color="primary"
                          size="medium"
                        >
                          Submit
                        </Button>
                      </div>
                    </form>
                    {messages && messages.length === 0 ? (
                      <div style={{ color: "#666" }}>
                        There are no responses yet
                      </div>
                    ) : (
                      <div></div>
                    )}
                    {isCategoriesUsed ? (
                      <div>
                        {Object.keys(categories).map((j) => {
                          return (
                            <>
                              <h3>{categories[j].name}</h3>
                              <div>
                                {messages
                                  .filter(
                                    (message) =>
                                      message.category === categories[j].hash
                                  )
                                  .map((message, i) => (
                                    <Zoom in={i !== ''}>
                                    <Card
                                      key={i + message}
                                      className={classes.root}
                                      variant="outlined"
                                    >
                                      <div key={i + message.id}>
                                        <div className="Admin-response-card">
                                          <div>
                                            <p>{<p>{message.text}</p>}</p>
                                            <p className="Admin-response-date">
                                              {this.timeDifference(
                                                Date.now(),
                                                message.timestamp
                                              )}
                                            </p>
                                          </div>
                                        </div>
                                        {/* <Divider /> */}
                                      </div>
                                    </Card>
                                    </Zoom>
                                  ))}
                              </div>
                              <div style={{ marginBottom: "40px" }}> </div>
                            </>
                          );
                        })}
                        {messages.filter((message) =>
                          this.isMessageUncategorized(message, categories)
                        ).length !== 0 ? (
                          <div>
                            <h3
                              style={{
                                paddingTop: "10px",
                                fontWeight: "500",
                                color: "#666",
                              }}
                            >
                              Uncategorized
                            </h3>
                            {messages
                              .filter((message) =>
                                this.isMessageUncategorized(message, categories)
                              )
                              .map((message, i) => (
                                <Zoom in={i !== ''}>
                                <Card
                                  key={i + message}
                                  className={classes.root}
                                  variant="outlined"
                                >
                                  <div key={i + message.id}>
                                    <div className="Admin-response-card">
                                      <div>
                                        <p>{message.text}</p>
                                        <p className="Admin-response-date">
                                          {this.timeDifference(
                                            Date.now(),
                                            message.timestamp
                                          )}
                                        </p>
                                      </div>
                                    </div>
                                    <Divider />
                                  </div>
                                </Card>
                                </Zoom>
                              ))}
                          </div>
                        ) : (
                          <div></div>
                        )}
                      </div>
                    ) : (
                      <div>
                        {messages
                          .filter((message) =>
                            this.isMessageUncategorized(message, categories)
                          )
                          .map((message, i) => (
                            <Zoom in={i !== ''}>
                            <Card
                              key={i + message.id}
                              className={classes.root}
                              variant="outlined"
                            >
                              <div>
                                <div className="Admin-response-card">
                                  <div>
                                    <p>{message.text}</p>
                                    <p className="Admin-response-date">
                                      {this.timeDifference(
                                        Date.now(),
                                        message.timestamp
                                      )}
                                    </p>
                                  </div>
                                  {/* <div className="Admin-responses-delete">
                                    <Tooltip title="Delete response">
                                      <DeleteIcon
                                        onClick={() => {
                                          this.handleDialogOpen(message);
                                        }}
                                        className="Admin-responses-delete-icon"
                                      />
                                    </Tooltip>
                                  </div> */}
                                </div>
                                <Divider />
                              </div>
                            </Card>
                            </Zoom>
                          ))}
                      </div>
                    )}

                    {/* <div className="Admin-responses-category-modal">
                      <CategoryModal
                        publicUrl={publicUrl}
                        categories={categories}
                        isOpen={categoryModalOpen}
                        onUpdate={(items) => this.updateIndex(items)}
                        handleClose={(event, items) => {
                          this.updateIndex(items);
                          this.setState({ categoryModalOpen: false });
                        }}
                      />
                    </div> */}
                    {/* <DeleteDialog
                      title={"Permanently delete message?"}
                      isDialogOpen={isDeleteDialogOpen}
                      content={messageToDelete && messageToDelete.text}
                      onDelete={this.deleteResponse}
                      handleClose={() => {
                        this.setState({ isDeleteDialogOpen: false });
                      }}
                    /> */}
                  </div>
                </div>
              </div>
            )}
          </Grid>
        </Grid>
      </div>
    );
  }
}

// const CategoryDropdown = ({
//   categories,
//   onCategorySelect,
//   preselectedCategory = "",
// }) => {
//   const [selectedCategory, setSelectedCategory] = React.useState(
//     preselectedCategory
//   );

//   return (
//     <FormControl variant="filled" className="Admin-responses-dropdown">
//       <InputLabel id="demo-simple-select-filled-label">Category</InputLabel>
//       <Select
//         labelId="demo-simple-select-filled-label"
//         id="demo-simple-select-filled"
//         // color="secondary"
//         value={selectedCategory}
//         onChange={(event) => {
//           setSelectedCategory(event.target.value);
//           onCategorySelect(event.target.value);
//         }}
//       >
//         <MenuItem value="">
//           <em>None</em>
//         </MenuItem>
//         {categories &&
//           categories.map((category, index) => (
//             <MenuItem value={category.hash} key={index + category}>
//               {category.name}
//             </MenuItem>
//           ))}
//       </Select>
//     </FormControl>
//   );
// };
export default withStyles(styles, { withTheme: true })(App);
