import React, { useState, useEffect } from "react";
import {
  Button,
  TextField,
  ListItem,
  ListItemText,
  Modal,
} from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";
import "./CategoryModal.css";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import DeleteDialog from "./DeleteDialog";
import { db } from "../../firebase";

export default function CategoryModal({
  publicUrl,
  categories,
  isOpen,
  handleClose,
  onUpdate,
}) {
  const [items, setItems] = useState(null);
  const [categoryInput, setCategoryInput] = useState("");
  const [isDeleteDialogOpen, setDeleteDialogOpen] = useState(false);
  const [categoryToDelete, setCategoryToDelete] = useState(null);

  //Make array from items.index s
  //Get max() index from items
  //add new category with index and name to categoryRef

  useEffect(() => {
    setItems(categories);
  }, [categories]);

  const getItemStyle = (isDragging, draggableStyle) => ({
    // some basic styles to make the items look a bit nicer
    userSelect: "none",
    padding: 8,
    margin: `0 0 8px 0`,
    background: "none",

    // change background colour if dragging
    background: isDragging ? "#DFEEFF" : "none",

    // styles we need to apply on draggables
    ...draggableStyle,
  });

  const getListStyle = (isDraggingOver) => ({
    // background: isDraggingOver ? "lightblue" : "none",
    // padding: 8,
    // width: 250,
  });

  const reorder = (list, startIndex, endIndex) => {
    const result = Array.from(list);
    const [removed] = result.splice(startIndex, 1);
    result.splice(endIndex, 0, removed);

    return result;
  };

  const onDragEnd = (result) => {
    // dropped outside the list
    if (!result.destination) {
      return;
    }
    const changedItems = reorder(
      items,
      result.source.index,
      result.destination.index
    );
    setItems(changedItems);
  };

  const addCategory = () => {
    db.ref(`/userdata/${publicUrl}/content/categories`)
      .push({
        index: items.length,
        name: categoryInput,
      })
      .then(() => {
        setCategoryInput("");
      });
  };

  return (
    <>
      <DeleteDialog
        title={"Permanently delete category?"}
        isDialogOpen={isDeleteDialogOpen}
        content={categoryToDelete && categoryToDelete.name}
        onDelete={() => {
          const restItems = items.filter(
            (restItem) => restItem.hash !== categoryToDelete.hash
          );
          onUpdate(restItems);
          setDeleteDialogOpen(false);
        }}
        handleClose={() => {
          setDeleteDialogOpen(false);
          setCategoryToDelete(null);
        }}
      />
      <Modal open={isOpen} onClose={(event) => handleClose(event, items)}>
        <div className="Admin-responses-category-modal">
          <div className="Admin-responses-category-container">
            <h2>Arrange categories</h2>
            <h3>New category</h3>
            <form
              onSubmit={(event) => event.preventDefault()}
              className="Admin-responses-category-modal-entry"
            >
              <TextField
                size="small"
                value={categoryInput}
                onChange={(e) => setCategoryInput(e.target.value)}
                onSubmit={(event) => addCategory()}
                id="outlined-basic"
                fullWidth
                label="Category name"
                variant="outlined"
                inputProps={{
                  maxLength: "100",
                }}
              />
              <Button
                onClick={() => {
                  addCategory();
                }}
                style={{ marginLeft: "20px" }}
                disabled={categoryInput === ""}
                variant="contained"
                color="primary"
                type="submit"
              >
                Add
              </Button>
            </form>

            <h3 style={{ marginTop: "30px" }}>Categories</h3>
            <div>
              {items && items.length === 0 ? (
                <div style={{ color: "#666" }}>
                  Start adding categories to see them here
                </div>
              ) : (
                <div></div>
              )}
              <div
                className="Scrollable"
                style={{ maxHeight: "300px", overflowX: "hidden" }}
              >
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable droppableId="droppable">
                    {(provided, snapshot) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        style={getListStyle(snapshot.isDraggingOver)}
                      >
                        {items.map((item, i) => (
                          <div>
                            <Draggable
                              key={item.index}
                              draggableId={String(item.index)}
                              index={i}
                            >
                              {(provided, snapshot) => (
                                <ListItem
                                  // className="Category-modal-item"

                                  button
                                  ref={provided.innerRef}
                                  {...provided.draggableProps}
                                  {...provided.dragHandleProps}
                                  style={getItemStyle(
                                    snapshot.isDragging,
                                    provided.draggableProps.style
                                  )}
                                >
                                  <CloseIcon
                                    className="Category-modal-delete"
                                    onClick={() => {
                                      // const restItems = items.filter(
                                      //   (restItem) => restItem.hash !== item.hash
                                      // );
                                      // onUpdate(restItems);
                                      setDeleteDialogOpen(true);
                                      setCategoryToDelete(item);
                                    }}
                                  />
                                  <ListItemText primary={`${item.name}`} />
                                </ListItem>
                              )}
                            </Draggable>
                          </div>
                        ))}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              </div>
            </div>
            <div className="Admin-responses-category-modal-button">
              <Button
                onClick={(event) => handleClose(event, items)}
                color="primary"
              >
                Done
              </Button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
}
