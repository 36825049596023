import React, { Component } from "react";
import "./App.css";
import { BrowserRouter, Route, Switch, Link } from "react-router-dom";
import Home from "./pages/home";
import Layout from "./pages/layout";
import Participant from "./pages/participantPage";
import Admin from "./pages/adminPage";
import FourOhFour from "./pages/FourOhFour";
import firebase from "./firebase";

class App extends React.Component {
  render() {
    return (
      <Switch>
        <Route
          exact
          path="/"
          render={(props) => (
            <Layout title={"Moored – Collaborative team meetings"}>
              <Home {...props} />
            </Layout>
          )}
        />
        <Route
          path="/404"
          render={(props) => (
            <Layout title={"404 — Page does not exist"}>
              <FourOhFour {...props} />
            </Layout>
          )}
        />
        <Route
          path="/b/:publicUrl/:adminExtension"
          render={(props) => (
            <Layout title={"Moored — Board owner page"}>
              <Admin {...props} />
            </Layout>
          )}
        />
        <Route
          path="/b/:publicUrl"
          render={(props) => (
            <Layout title={"Moored — Board"}>
              <Participant {...props} />
            </Layout>
          )}
        />
        <Route
          path="/:invalidPage"
          render={(props) => (
            <Layout title={"404 — Page does not exist"}>
              <FourOhFour {...props} />
            </Layout>
          )}
        />
      </Switch>
    );
  }
}

export default App;
