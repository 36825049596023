import firebase from "firebase";

var config = {
  /* COPY THE ACTUAL CONFIG FROM FIREBASE CONSOLE */
  apiKey: process.env.REACT_APP_API_KEY,
  authDomain: process.env.REACT_APP_AUTH_DOMAIN,
  databaseURL: process.env.REACT_APP_DATABASE_URL,
  projectId: process.env.REACT_APP_PROJECT_ID,
  storageBucket: process.env.REACT_APP_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_MESSAGING_SENDER_ID,
  appId: process.env.REACT_APP_APP_ID,
  measurementId: process.env.REACT_APP_MEASUREMENT_ID,
};
const app = firebase.initializeApp(config);
firebase.analytics();

export const db = app.database();
export const getUserId = () => firebase.auth().currentUser.uid;
export const signUserIn = () => {
  return firebase
    .auth()
    .signInAnonymously()
    .then(({ user }) => {
      console.log("user signed in");
    })
    .catch((error) => {
      var errorCode = error.code;
      var errorMessage = error.message;
      console.log(
        "user couldn't anonymously sign in:",
        errorCode,
        errorMessage
      );
      // ...
    });
};

export default app;
