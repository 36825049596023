import React, { useEffect } from "react";
import "./FourOhFour.css";
import { makeid } from "../shared/utils";
import { useHistory, Link } from "react-router-dom";
import { db, signUserIn } from "../firebase";
import logo from "../assets/moored_logo.png";


class fourOhFour extends React.Component {

    componentDidMount() {
        document.body.style.overflow = "hidden";
    }
    
    componentDidUnmount() {
        document.body.style.overflow = "visible"; // or restore the original value
    }

    render() {
        return (
            <div className="Top-container">
            <div>
                <Link to="/">
                <img
                    className="Four-logo"
                    src={logo}
                    alt="Moored logo"
                    width="150px"
                />
                </Link>
                {/* <div className="Home-lines"></div>
                <div className="Home-lines" style={{marginTop: '40px', width: '500px'}}></div>
                <div className="Home-lines" style={{marginTop: '60px', width: '60px', backgroundColor: "gray"}}></div> */}
            </div>
                <div className="FourText">
                    <h1>Lost at sea?</h1>
                    <p>Let's get you <Link to="/" style={{color: "#d6425d"}}>moored</Link></p>
                </div>
                <div className="Wave-bottom-container">
                    <div>
                        <div className="FourO">
                            <div className="FourORotate">
                                404
                            </div>
                        </div>
                        <div>
                            <div className="Wave-bottom"></div>
                            <div className="Wave-bottom"></div>
                        </div>
                    </div>
                    <div className="Deep-sea"></div>
                </div>

            </div>
        )
    }
}

export default fourOhFour;
